<template>
	<section class="hero" :class="type" :aria-label="header" :style="backgroundStyle">
		<div class="hero-content">
			<h2 class="hero-content-header" v-html="header"></h2>
			<p class="hero-content-text" v-html="text"></p>
			<slot></slot>
		</div>
	</section>
</template>

<script>
//This is a DS component, it will be moved to DS repo
//TODO Move component to DS repo for use
export default {
	name: 'Hero',
	props: {
		/**
		 * header is the Jumbotron main text
		 */
		header: {
			type: String,
			default: '',
			validator: value => {
				return typeof value === 'string';
			},
		},
		/**
		 * text is the sub header text
		 */
		text: {
			type: String,
			default: '',
			validator: value => {
				return typeof value === 'string';
			},
		},
		/**
		 * backgroundStyle is the background image URL or color
		 */
		backgroundStyle: {
			type: String,
			default: '',
		},
		/**
		 * type is the type of Hero
		 * `light` or `dark`
		 */
		type: {
			type: String,
			default: 'dark',
			validator: value => {
				return value.match(/(dark|light)/);
			},
		},
	},
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables.scss';

.hero,
.hero-content {
	display: flex;
	align-items: center;
	flex-direction: column;
}
.hero {
	justify-content: space-evenly;
	text-align: center;
	width: 100%;
	overflow: hidden;
	background-size: cover !important;
	padding: 0px 30px 15px;
	margin: 0 auto;
	backface-visibility: hidden;
	&.light {
		background-color: white;
		.hero-content-header,
		.hero-content-text {
			color: black;
		}
	}
}
.hero-content {
	max-width: 1200px;
}

.hero-content-header {
	max-width: 800px;
	color: white;
	font-size: 40px;
}
.hero-content-text {
	max-width: 800px;
	color: #999999;
}

@media (max-width: $screen-xs) {
	.hero-content-header {
		font-size: 24px;
	}
}
</style>
