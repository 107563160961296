<template>
	<section class="card-container" :style="card.cardStyle">
		<div v-if="card.imageSrc" class="card-image">
			<img :src="card.imageSrc" :alt="card.key" />
		</div>
		<h2 class="card-header" v-html="card.header"></h2>
		<p class="card-text" v-html="card.text"></p>
		<slot></slot>
	</section>
</template>

<script>
//This is a DS component, it will be moved to DS repo
//TODO Move component to DS repo for use
export default {
	name: 'BasicCard',
	props: {
		card: {
			type: Object,
			default: () => ({}),
		},
	},
};
</script>

<style lang="scss" scoped>
.card-container {
	border-radius: 16px;
	box-shadow: 0 8px 50px 0 rgba(0, 0, 0, 0.04);
	padding: 15px;
}
.card-header,
.card-text {
	color: black;
	margin-top: 30px;
}
.card-image {
	margin: 30px auto;
	width: 50%;
}
</style>
