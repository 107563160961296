
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

//components
import BasicBanner from '@/components/ds/BasicBanner.vue';
import BasicButton from '@/ui-components/BasicButton.vue';
import BasicCard from '@/components/ds/BasicCard.vue';
import Hero from '@/components/ds/Hero.vue';

//interfaces
interface ButtonActionPayload {
	url: string;
	action: string;
	label?: string;
}

interface ButtonActionList {
	[name: string]: ButtonActionPayload;
}

//helpers
import { TrackingHelper } from '@/helpers/tracking/tracking-helper';

const Routing = namespace('routing');

@Component({
	name: 'Talent',
	components: {
		Hero,
		BasicCard,
		BasicButton,
		BasicBanner,
	},
})
export default class Talent extends Vue {
	private urlList: ButtonActionList = {
		JOB_OFFER: { url: JW_CONFIG.JOBSITE_URL, action: 'open_positions_clicked' },
		BLOG_POST: { url: '/blog/post/justwatch-company-culture/', action: 'learn_more_clicked' },
	};

	@Routing.State urlMetadata: any;
	@Routing.State urlMetadataPromise: Promise<any>;

	get ASSETS_DIR() {
		return ASSETS_DIR;
	}

	get getHeaderOptions() {
		return {
			header: this.getTranslation('HERO_HEADER'),
			text: this.getTranslation('HERO_TEXT'),
			backgroundStyle:
				'background:linear-gradient(rgba(0,0,0,0.85) 0%, rgba(0,0,0,0.85) 100%), url(https://static.justwatch.com/backdrop/427302/s1920/Good-Will-Hunting-Der-gute-Will-Hunting) no-repeat center top scroll',
		};
	}

	get getWhoWeAreOptions() {
		return {
			header: this.getTranslation('WHOWEARE_HEADER'),
			text: this.getTranslation('WHOWEARE_TEXT'),
		};
	}

	get getOurValuesOptions() {
		return {
			header: this.getTranslation('CARDS_HEADER'),
			text: this.getTranslation('CARDS_TEXT'),
			type: 'light',
		};
	}

	get getCardContent() {
		return ['learn', 'deliver', 'create'].map(key => {
			return {
				key,
				header: this.getTranslation(`CARDS_${key}_HEADER`),
				text: this.getTranslation(`CARDS_${key}_TEXT`),
				imageSrc: `/${this.ASSETS_DIR}/img/talent/${key}.svg`,
			};
		});
	}

	get getBlogSectionOptions() {
		return {
			text: this.getTranslation('BLOGPOST_TEXT'),
		};
	}

	get getBannerOptions() {
		return {
			header: this.getTranslation('BANNER_TEXT'),
			buttonLable: this.getTranslation('JOB_OFFERS_BUTTON'),
		};
	}

	async mounted() {
		await this.urlMetadataPromise;
	}

	redirectRouteTo(options: ButtonActionPayload) {
		const { action, label, url } = options;
		TrackingHelper.trackEvent('userinteraction', { action, label });
		window.open(url, '_blank', 'noopener');
	}

	getTranslation(key: string) {
		if (this.urlMetadata?.html_content) {
			return this.urlMetadata?.html_content[key.toUpperCase()] as string;
		}
	}
}
