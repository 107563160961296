import { render, staticRenderFns } from "./BasicCard.vue?vue&type=template&id=53e6d79b&scoped=true"
import script from "./BasicCard.vue?vue&type=script&lang=js"
export * from "./BasicCard.vue?vue&type=script&lang=js"
import style0 from "./BasicCard.vue?vue&type=style&index=0&id=53e6d79b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53e6d79b",
  null
  
)

export default component.exports