import { render, staticRenderFns } from "./Hero.vue?vue&type=template&id=f0283030&scoped=true"
import script from "./Hero.vue?vue&type=script&lang=js"
export * from "./Hero.vue?vue&type=script&lang=js"
import style0 from "./Hero.vue?vue&type=style&index=0&id=f0283030&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f0283030",
  null
  
)

export default component.exports