<template>
	<div class="banner">
		<div class="banner-content">
			<div class="banner-background" :style="getBackgroundImage"></div>
			<p class="banner-content-header" v-if="!!header">{{ header }}</p>
			<BasicButton
				class="banner-content-button"
				color="primary"
				size="large"
				v-on="$listeners"
				v-if="!!buttonLable"
			>
				{{ buttonLable }}
			</BasicButton>
		</div>
	</div>
</template>

<script>
//This is a DS component, it will be moved to DS repo
//TODO Move component to DS repo for use

import BasicButton from '@/ui-components/BasicButton.vue';

export default {
	name: 'BasicBanner',
	components: {
		BasicButton,
	},
	props: {
		header: {
			type: String,
			default: '',
		},
		buttonLable: {
			type: String,
			default: '',
		},
	},
	computed: {
		getBackgroundImage() {
			const imageUrl = `/${ASSETS_DIR}/img/talent/movies_mosiac.png`;
			return `background:url(${imageUrl})`;
		},
	},
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables.scss';

.banner {
	padding: 30px 30px 153px;
	background-color: $jw-color-white;
}

.banner-content {
	max-width: 1200px;
	margin: 0 auto;
	border-radius: 16px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-content: space-evenly;
	height: 333px;
	padding: 20px;
	position: relative;
	overflow: hidden;
}

.banner-background {
	position: absolute;
	width: 111%;
	height: 100%;
	background-size: cover !important;
}

.banner-content-header {
	text-align: center;
	font-size: 24px;
	line-height: 35px;
	max-width: 695px;
	color: $jw-color-white;
	z-index: 1;
}

.banner-content-button {
	z-index: 1;
}
</style>
